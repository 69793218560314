<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <b-tabs>
        <b-tab title="List of Users">
          <div v-if="isList" class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-sm-3 col-lg-4">
                  <h5 class="card-title">{{ pageTitle }} List</h5>
                </div>
                <div class="col-sm-9 col-lg-8">
                  <div class="row justify-content-end">
                    <div class="my-1 col-sm-4">
                      <VSelect
                        v-model="filterCategory"
                        placeholder="-- All Categories --"
                        :options="mrCategories"
                        :reduce="(v) => v.mc_slug"
                        label="mc_name"
                      >
                      </VSelect>
                    </div>
                    <div class="my-1 col-sm-4">
                      <VSelect
                        v-model="filterCountry"
                        placeholder="-- All Countries --"
                        :options="mrCountry"
                        :reduce="(v) => v.value"
                        label="label"
                      >
                      </VSelect>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="my-1 col-sm-6 col-lg-5">
                      <form @submit.prevent="doFilter()">
                        <div class="form-group mb-0">
                          <div class="input-group">
                            <input
                              v-model="filter.search"
                              type="text"
                              class="form-control"
                              placeholder="Type keyword then enter..."
                            />
                            <div class="input-group-append">
                              <button class="btn btn-info" type="submit">
                                <i class="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="my-1 col-sm-2">
                      <b-button @click="doReset" class="btn btn-info btn-block"
                        >Reset</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row mt-4">
                <div class="table-responsive">
                  <table class="table product-overview table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Registration Date</th>
                        <th>Full Name</th>
                        <th>Contact</th>
                        <th>Country</th>
                        <th>Categories</th>
                        <!-- <th>Status</th> -->
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
                      <tr v-for="(v,k) in data.data" :key="k">
                        <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                        
                        <td>{{ v.created_at | moment('MMM DD, YYYY hh:mm A') }}</td>

                        <td>
                          <span class="d-block">{{ v.full_name }}</span>
                          <span class="d-block text-primary">{{ v.number_pre_reg }}</span>
                        </td>
                        <td>
                          <span class="d-block">{{ v.dial_code }}{{ v.phone }}</span>
                          <a target="_blank" :href="'mailto:' + v.email" class="light fs-1rem text-wrap"><span class="d-block text-primary">{{ v.email }}</span></a>
                        </td>
                        <td>{{ v.country }}</td>
                        <td>
                          <template v-for="(v1, k1) in v.categories">
                            <LabelStatus class="m-1" type="category" :status="v1.prc_name" :key="k1" />
                          </template>
                        </td>

                        <td>
                          <a
                            href="javascript:;"
                            class="text-inverse icon_action act_icon"
                            v-tooltip="'Detail'"
                            @click.prevent="showModalDetail(v)"
                          >
                            <i class="fas fa-eye"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <div
                    class="pagination-flat float-right"
                    v-if="data.data !== false && data.data.length"
                  >
                  <!-- please contact pic to change the pagination -->
                    <Pagination
                      :data="data"
                      :limit="2"
                      @pagination-change-page="doPaginate"
                    >
                      <span slot="prev-nav"
                        >Previous</span>
                      <span slot="next-nav"
                        >Next</span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="card">
            <div class="card-body">
              <h5 class="card-title mb-3">
                {{ isAdd ? "Add" : "Edit" }} {{ pageTitle }}
              </h5>

              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                <form @submit.prevent="handleSubmit(doSubmitCRUD)">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">
                              Status
                              <span class="text-danger mr5">*</span>
                            </label>
                            <div>
                              <InputRadio
                                v-model="row[statusKey]"
                                name="status"
                                option="Y"
                              />
                              <InputRadio
                                v-model="row[statusKey]"
                                name="status"
                                option="N"
                              />
                            </div>
                            <VValidate
                              name="Status"
                              v-model="row[statusKey]"
                              :rules="mrValidation[statusKey]"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">
                              Is Featured?
                              <span class="text-danger mr5">*</span>
                            </label>
                            <div>
                              <InputRadio
                                v-model="row.aiak_is_featured"
                                name="is_featured"
                                option="Y"
                                label="Yes"
                              />
                              <InputRadio
                                v-model="row.aiak_is_featured"
                                name="is_featured"
                                option="N"
                                label="No"
                              />
                            </div>
                            <VValidate
                              name="Status"
                              v-model="row.aiak_is_featured"
                              :rules="mrValidation.aiak_is_featured"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="row">
                        <b-col md="6">
                          <b-form-group>
                            <label>
                              Image <span class="text-danger mr5">*</span>
                            </label>
                            <Uploader
                              :readonly="!moduleRole('Edit')"
                              v-model="row.aiak_fallback_img"
                              :param="{ thumbnail: true }"
                              :squarePreview="true"
                              type="gallery"
                              label="Image"
                              @data="(v) => (row.aiak_img = v)"
                            />
                            <VValidate
                              name="Image"
                              v-model="row.aiak_img"
                              :rules="mrValidation.aiak_img"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label>
                              Image Small <span class="text-danger mr5">*</span>
                            </label>
                            <Uploader
                              :readonly="!moduleRole('Edit')"
                              v-model="row.aiak_fallback_small_img"
                              :param="{ thumbnail: true }"
                              :squarePreview="true"
                              type="gallery_small"
                              label="Image"
                              @data="(v) => (row.aiak_small_img = v)"
                            />
                            <VValidate
                              name="Image"
                              v-model="row.aiak_small_img"
                              :rules="mrValidation.aiak_small_img"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <BoField
                            label="Alt Image"
                            placeholder="Alt Image"
                            v-model="row.aiak_alt_img"
                            :rules="mrValidation.aiak_alt_img"
                          />
                        </b-col>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12 text-right">
                      <router-link
                        :to="{ name: $route.name }"
                        class="btn btn-rounded btn-light mr-2"
                        >Cancel</router-link
                      >
                      <button type="submit" class="btn btn-rounded btn-info">
                        {{ isAdd ? "Submit" : "Update" }}
                      </button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <b-modal id="modalDetail" size="xl" title="Message Detail">
        <template #default>
          <div class="row">
            <div class="col-md-4">
              <p class="label_title">Registration Number</p>
              <p class="font-weight-bold">{{ detailData.number_pre_reg }}</p>
            </div>
            <div class="col-md-4">
              <p class="label_title">Registration Date</p>
              <p class="font-weight-bold">
                {{ detailData.created_at | moment('MMM DD, YYYY hh:mm A') }}
              </p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-4">
              <p class="font-weight-bold text-primary">Personal Details</p>

              <div class="row">
                <div class="col-md-12">
                  <p class="label_title">Full Name</p>
                  <p class="font-weight-bold">{{ detailData.full_name }}</p>
                </div>
                <div class="col-md-12">
                  <p class="label_title">NIK / Passport Number</p>
                  <p class="font-weight-bold">{{ detailData.identity_number }}</p>
                </div>
                <div class="col-md-12">
                  <p class="label_title">Country</p>
                  <p class="font-weight-bold">{{ detailData.country }}</p>
                </div>
                <div class="col-md-12">
                  <p class="label_title">Age</p>
                  <p class="font-weight-bold">{{ detailData.age }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <p class="font-weight-bold text-primary">Contact Details</p>

              <div class="row">
                <div class="col-md-12">
                  <p class="label_title">Email Address</p>
                  <p class="font-weight-bold">{{ detailData.email }}</p>
                </div>
                <div class="col-md-12">
                  <p class="label_title">Phone Number</p>
                  <p class="font-weight-bold">{{ detailData.dial_code }}{{ detailData.phone }} </p>
                </div>
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-md-6">
              <p class="font-weight-bold text-primary">Category Details</p>

              <table class="table product-overview table-bordered">
                <thead>
                  <tr>
                    <th>Saturday - October 12, 2024</th>
                    <th>{{ saturdayMar ?? '-' }}</th>
                  </tr>
                  <tr>
                    <th>Sunday - October 13, 2024</th>
                    <th>{{ sundayMar ?? '-' }}</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
          </div>
        </template>

        <template #modal-footer="{ hide }">
          <b-button variant="outline-secondary" @click="hide()">
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
  },
  data() {
    return {
      idKey: "aiak_id",
      statusKey: "aiak_is_active",
      editor: ClassicEditor,
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },
      detailData: [],
      mrCategories: [],
      mrCountry: []
    };
  },
  computed: {
    filterCategory: {
      get() {
        return this.filter.category;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          category: newValue,
        };
        this.doFilter();
      },
    },

    filterCountry: {
      get() {
        return this.filter.country;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          country: newValue,
        };
        this.doFilter();
      },
    },

    saturdayMar: {
      get() {
        const categories = (this.detailData || {}).categories || [];
        const desiredCategories = ['kids-dash', '5KM', '5KM-group', '10KM'];
        
        const filteredCategories = categories.filter(category => desiredCategories.includes(category.prc_name));
        
        return filteredCategories.map(category => category.prc_name).join(', ');
      },
    },

    sundayMar: {
      get() {
        const categories = (this.detailData || {}).categories || [];
        const desiredCategories = ['21KM', '42KM'];
        
        const filteredCategories = categories.filter(category => desiredCategories.includes(category.prc_name));
        
        return filteredCategories.map(category => category.prc_name).join(', ');
      },
    }
  },
  methods: {
    showModalDetail(v) {
      this.detailData = v;
      this.$bvModal.show('modalDetail');
    },
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch: {
    $route: {
      handler() {
        this.apiGet();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.add_hero {
  min-height: 130px;
  border: 2px dotted rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>